import React from "react";
import { navigateTo } from "gatsby-link";
import Recaptcha from "react-google-recaptcha";

import TextAreaField from '../components/TextAreaField';
import TextInputField from '../components/TextInputField';
import Layout from '../components/Layout'

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    // TODO: validation
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    // (!this.state['g-recaptcha-response']) ? updateErrorMessage('Vous avez oublier de valider Recaptcha') : null

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => navigateTo(form.getAttribute("action")))
    .catch(error => alert(error));
  };

  updateErrorMessage = errorMessage => this.setState({ error: errorMessage })

  render() {
    return (
      <Layout>
        <div className="container">
          <div className="notification">
            <h1 className="title">Contact</h1>
            <h3>{this.state.error}</h3>
            {/* <Formsy> */}
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="surname"
                netlify-recaptcha="true"
                onSubmit={ this.handleSubmit }
                action="/merci/"
                >

                <input type="hidden" name="surname"/>

                <TextInputField 
                  label="Nom"
                  type="text"
                  name="nom"
                  onChange={ this.handleChange }
                  errorMessage={ null }
                  required
                />

                <TextInputField 
                  label="Titre"
                  type="text"
                  name="titre"
                  onChange={ this.handleChange }
                  errorMessage={ null }
                  required
                />

                <TextInputField 
                  label="Email"
                  type="text"
                  name="email"
                  onChange={ this.handleChange }
                  errorMessage={ null }
                  required
                />

                <TextAreaField 
                  label="Message"
                  type="textarea"
                  name="message"
                  onChange={ this.handleChange }
                  errorMessage={ null }
                  required
                />

                <Recaptcha
                  ref="recaptcha"
                  sitekey={RECAPTCHA_KEY}
                  onChange={this.handleRecaptcha}
                />

                <div className="field is-grouped">
                  <div className="control">
                    <button className="button is-link" type="submit">Submit</button>
                  </div>
                </div>

                <noscript>
                  <p>This form won’t work with Javascript disabled</p>
                </noscript>
              </form>
            {/* </Formsy> */}
          </div>
        </div>
      </Layout>
    );
  }
}