import React from "react";

const TextInputField = ({ label, errorMessage, ...rest }) => (
  <div className="field">
    <label className="label">{ label }</label>
    <div className="control">
      <input className="input" { ...rest }/>
    </div>
    { errorMessage && <p className="help is-danger">{ errorMessage }</p> }
  </div>
)

export default TextInputField